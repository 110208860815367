import Dashboard from '../containers/Dashboard/loadable';
import Settings from '../containers/Settings/loadable';
import Login from '../containers/Login/loadable';
import { ROLES } from '../utils/constants';
import SignUp from '../containers/SignUp/loadable';
import ForgotPassword from '../containers/ForgotPassword/loadable';
import Prospects from '../containers/Prospects/loadable';
import Marketing from '../containers/Marketing/loadable';
import Scripts from '../containers/Scripts/loadable';
import GenerateEmail from '../containers/GenerateEmail/loadable';
import Billing from '../containers/Billing/loadable';
import Accounts from '../containers/Accounts/loadable';
import SystemStatus from '../containers/SystemStatus/loadable';
import SnippetMaster from '../containers/SnippetMaster/loadable';
import Category from '../containers/Category/loadable';
import Ranking from '../containers/Ranking/loadable';
import ClientMaster from '../containers/ClientMaster/loadable';
import Transaction from '../containers/Transaction/loadable';
import EmailType from '../containers/EmailType/loadable';
import ResetPassword from '../containers/ResetPassword/loadable';
import ProPackage from '../containers/ProPackage/loadable';
import Checkout from '../containers/Checkout/loadable';
import { TurnedIn } from '@material-ui/icons';

const routeTypes = { public: 'public', private: 'private' };

export const routeArray = [
  {
    path: '/',
    component: Login,
    exact: true,
    breadCrumbKey: 'login',
    routeType: routeTypes.public,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    exact: true,
    routeType: routeTypes.private,
  },
  {
    path: '/settings',
    component: Settings,
    exact: true,
    breadCrumbKey: 'forgot password',
    routeType: routeTypes.public,
    roles: [ROLES.ADMIN],
  },
  {
    path: '/signup',
    component: SignUp,
    exact: true,
    breadCrumbKey: 'Sign up',
    routeType: routeTypes.public,
  },
  {
    path: '/forgotPassword',
    component: ForgotPassword,
    exact: true,
    breadCrumbKey: 'Forgot Password',
    routeType: routeTypes.public,
  },
  {
    path: '/prospects',
    component: Prospects,
    exact: true,
    breadCrumbKey: 'Prospects',
    routeType: routeTypes.private,
  },
  {
    path: '/marketing',
    component: Marketing,
    exact: true,
    breadCrumbKey: 'Marketing',
    routeType: routeTypes.private,
  },
  {
    path: '/scripts',
    component: Scripts,
    exact: true,
    breadCrumbKey: 'Script',
    routeType: routeTypes.private,
  },
  {
    path: '/generate-email',
    component: GenerateEmail,
    exact: true,
    breadCrumbKey: 'Generate Email',
    routeType: routeTypes.private,
  },
  {
    path: '/billing',
    component: Billing,
    exact: true,
    breadCrumbKey: 'Billing',
    routeType: routeTypes.private,
  },
  {
    path: '/accounts',
    component: Accounts,
    exact: true,
    breadCrumbKey: 'Accounts',
    routeType: routeTypes.private,
  },
  {
    path: '/system-status',
    component: SystemStatus,
    exact: true,
    breadCrumbKey: 'System Status',
    routeType: routeTypes.private,
  },
  {
    path: '/snippet-master',
    component: SnippetMaster,
    exact: true,
    breadCrumbKey: 'Snippet Master',
    routeType: routeTypes.private,
  },
  {
    path: '/category',
    component: Category,
    exact: true,
    breadCrumbKey: 'Category',
    routeType: routeTypes.private,
  },
  {
    path: '/ranking',
    component: Ranking,
    exact: true,
    breadCrumbKey: 'Ranking',
    routeType: routeTypes.private,
  },
  {
    path: '/client-master',
    component: ClientMaster,
    exact: true,
    breadCrumbKey: 'Client Master',
    routeType: routeTypes.private,
  },
  {
    path: '/transactions',
    component: Transaction,
    exact: true,
    breadCrumbKey: 'Transaction',
    routeType: routeTypes.private,
  },
  {
    path: '/email-type',
    component: EmailType,
    exact: true,
    breadCrumbKey: 'Email Type',
    routeType: routeTypes.private,
  },
  {
    path: '/new-password',
    component: ResetPassword,
    exact: true,
    breadCrumbKey: 'Reset Password',
    routeType: routeTypes.public,
  },
  {
    path: '/pro-package',
    component: ProPackage,
    exact: true,
    breadCrumbKey: 'Pro Package',
    routeType: routeTypes.public,
  },
  {
    path: '/checkout/:id',
    component: Checkout,
    exact: true,
    breadCrumbKey: 'Checkout',
    routeType: routeTypes.public,
  },
];
