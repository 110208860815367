export const colors = {
  disable: '#F0F0F0',
  green: '#02D417',
  red: '#CF1414',
  grey: '#F4EBE8',
  purple: '#8702D4',
  primary: '#564FCC',
  secondary: '#36D6D8',
  tertiary: '#003558',
  info: '#0084FF',
  danger: '#ED9121',
  success: '#4CBB17',
  error: '#CF352E',
  greyDark: '#B8BABC',
  light: '#E6DCFA',
  hover: '#6C63FF',
  mainGradient: 'linear-gradient(to bottom,#582977, #2E163E)',
  chartsColors: {
    primary: '#431480',
    secondary: '#623899',
    tertiary: '#9E85BE',
    quaternary: '#EBDBFF',
  },
  borderColor: '#828282',
  background: {
    primary: '#F6F7FB',
    secondary: '#ffffff',
    tertiary: '#F7F9FF',
    light: '#FCFCFE',
    headerIcon: '#003558',
    drawer: '#002A46',
  },
  textColor: {
    primary: '#002A46',
    secondary: '#353535',
    dark: '#7F7F7F',
    light: '#ffffff',
  },
};
