import React from 'react';

const TransactionIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99996 1.6665C5.39996 1.6665 1.66663 5.39984 1.66663 9.99984C1.66663 14.5998 5.39996 18.3332 9.99996 18.3332C14.6 18.3332 18.3333 14.5998 18.3333 9.99984C18.3333 5.39984 14.6 1.6665 9.99996 1.6665ZM9.99996 16.6665C6.32496 16.6665 3.33329 13.6748 3.33329 9.99984C3.33329 6.32484 6.32496 3.33317 9.99996 3.33317C13.675 3.33317 16.6666 6.32484 16.6666 9.99984C16.6666 13.6748 13.675 16.6665 9.99996 16.6665ZM10.7416 9.24984C9.25829 8.75817 8.54163 8.44984 8.54163 7.6665C8.54163 6.8165 9.46663 6.50817 10.05 6.50817C11.1416 6.50817 11.5416 7.33317 11.6333 7.62484L12.95 7.0665C12.825 6.69984 12.2666 5.47484 10.7333 5.20817V4.1665H9.27496V5.2165C7.10829 5.68317 7.09163 7.5915 7.09163 7.68317C7.09163 9.57484 8.96663 10.1082 9.88329 10.4415C11.2 10.9082 11.7833 11.3332 11.7833 12.1332C11.7833 13.0748 10.9083 13.4748 10.1333 13.4748C8.61663 13.4748 8.18329 11.9165 8.13329 11.7332L6.74996 12.2915C7.27496 14.1165 8.64996 14.6082 9.26663 14.7582V15.8332H10.725V14.7998C11.1583 14.7248 13.2416 14.3082 13.2416 12.1165C13.25 10.9582 12.7416 9.9415 10.7416 9.24984Z"
        fill="white"
      />
    </svg>
  );
};

export default TransactionIcon;
