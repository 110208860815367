import React from 'react';

const MessageIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 3.3335H4.16667C3.50363 3.3335 2.86774 3.59689 2.3989 4.06573C1.93006 4.53457 1.66667 5.17045 1.66667 5.8335V14.1668C1.66667 14.8299 1.93006 15.4658 2.3989 15.9346C2.86774 16.4034 3.50363 16.6668 4.16667 16.6668H15.8333C16.4964 16.6668 17.1323 16.4034 17.6011 15.9346C18.0699 15.4658 18.3333 14.8299 18.3333 14.1668V5.8335C18.3333 5.17045 18.0699 4.53457 17.6011 4.06573C17.1323 3.59689 16.4964 3.3335 15.8333 3.3335ZM4.16667 5.00016H15.8333C16.0543 5.00016 16.2663 5.08796 16.4226 5.24424C16.5789 5.40052 16.6667 5.61248 16.6667 5.8335L10 9.90016L3.33333 5.8335C3.33333 5.61248 3.42113 5.40052 3.57741 5.24424C3.73369 5.08796 3.94565 5.00016 4.16667 5.00016ZM16.6667 14.1668C16.6667 14.3878 16.5789 14.5998 16.4226 14.7561C16.2663 14.9124 16.0543 15.0002 15.8333 15.0002H4.16667C3.94565 15.0002 3.73369 14.9124 3.57741 14.7561C3.42113 14.5998 3.33333 14.3878 3.33333 14.1668V7.7335L9.56667 11.5418C9.69335 11.615 9.83705 11.6535 9.98333 11.6535C10.1296 11.6535 10.2733 11.615 10.4 11.5418L16.6667 7.7335V14.1668Z"
        fill="#fff"
      />
    </svg>
  );
};

export default MessageIcon;
