import React from 'react';

const ClientMasterIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49996 9.99984C9.10829 9.99984 10.4166 8.6915 10.4166 7.08317C10.4166 5.47484 9.10829 4.1665 7.49996 4.1665C5.89163 4.1665 4.58329 5.47484 4.58329 7.08317C4.58329 8.6915 5.89163 9.99984 7.49996 9.99984ZM7.49996 5.83317C8.19163 5.83317 8.74996 6.3915 8.74996 7.08317C8.74996 7.77484 8.19163 8.33317 7.49996 8.33317C6.80829 8.33317 6.24996 7.77484 6.24996 7.08317C6.24996 6.3915 6.80829 5.83317 7.49996 5.83317ZM7.54163 14.1665H3.97496C4.79996 13.7498 6.22496 13.3332 7.49996 13.3332C7.59163 13.3332 7.69163 13.3415 7.78329 13.3415C8.06663 12.7332 8.55829 12.2332 9.14996 11.8332C8.54163 11.7248 7.96663 11.6665 7.49996 11.6665C5.54996 11.6665 1.66663 12.6415 1.66663 14.5832V15.8332H7.49996V14.5832C7.49996 14.4415 7.51663 14.2998 7.54163 14.1665ZM13.75 12.0832C12.2166 12.0832 9.16663 12.9248 9.16663 14.5832V15.8332H18.3333V14.5832C18.3333 12.9248 15.2833 12.0832 13.75 12.0832ZM14.7583 10.5665C15.3916 10.2082 15.8333 9.53317 15.8333 8.74984C15.8333 7.59984 14.9 6.6665 13.75 6.6665C12.6 6.6665 11.6666 7.59984 11.6666 8.74984C11.6666 9.53317 12.1083 10.2082 12.7416 10.5665C13.0416 10.7332 13.3833 10.8332 13.75 10.8332C14.1166 10.8332 14.4583 10.7332 14.7583 10.5665Z"
        fill="white"
      />
    </svg>
  );
};

export default ClientMasterIcon;
