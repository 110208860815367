import React from 'react';

const ProspectsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 9.16675C15 9.72508 15 10.2751 15 10.8334C16 10.8334 17.3 10.8334 18.3333 10.8334C18.3333 10.2751 18.3333 9.72508 18.3333 9.16675C17.3 9.16675 16 9.16675 15 9.16675Z"
        fill="white"
      />
      <path
        d="M13.3333 14.6749C14.1333 15.2666 15.175 16.0499 16 16.6666C16.3333 16.2249 16.6667 15.7749 17 15.3333C16.175 14.7166 15.1333 13.9333 14.3333 13.3333C14 13.7833 13.6667 14.2333 13.3333 14.6749Z"
        fill="white"
      />
      <path
        d="M17 4.66659C16.6667 4.22492 16.3333 3.77492 16 3.33325C15.175 3.94992 14.1333 4.73325 13.3333 5.33325C13.6667 5.77492 14 6.22492 14.3333 6.66659C15.1333 6.06659 16.175 5.29159 17 4.66659Z"
        fill="white"
      />
      <path
        d="M3.33333 7.5C2.41667 7.5 1.66667 8.25 1.66667 9.16667V10.8333C1.66667 11.75 2.41667 12.5 3.33333 12.5H4.16667V15.8333H5.83333V12.5H6.66667L10.8333 15V5L6.66667 7.5H3.33333ZM7.525 8.925L9.16667 7.94167V12.0583L7.525 11.075L7.125 10.8333H6.66667H3.33333V9.16667H6.66667H7.125L7.525 8.925Z"
        fill="white"
      />
      <path
        d="M12.9167 9.99992C12.9167 8.89159 12.4333 7.89159 11.6667 7.20825V12.7833C12.4333 12.1083 12.9167 11.1083 12.9167 9.99992Z"
        fill="white"
      />
    </svg>
  );
};

export default ProspectsIcon;
