import React from 'react';

const BillingIcon = ({ fill = 'white' }) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66667 0.666992H2C1.08333 0.666992 0.333334 1.41699 0.333334 2.33366V15.667C0.333334 16.5837 1.08333 17.3337 2 17.3337H12C12.9167 17.3337 13.6667 16.5837 13.6667 15.667V5.66699L8.66667 0.666992ZM2 15.667V2.33366H7.83333V5.66699H12V15.667H2ZM6.16667 14.8337H7.83333V14.0003H8.66667C9.125 14.0003 9.5 13.6253 9.5 13.167V10.667C9.5 10.2087 9.125 9.83366 8.66667 9.83366H6.16667V9.00033H9.5V7.33366H7.83333V6.50033H6.16667V7.33366H5.33333C4.875 7.33366 4.5 7.70866 4.5 8.16699V10.667C4.5 11.1253 4.875 11.5003 5.33333 11.5003H7.83333V12.3337H4.5V14.0003H6.16667V14.8337Z"
        fill={fill}
      />
    </svg>
  );
};

export default BillingIcon;
