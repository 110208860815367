import React from 'react';
import clsx from 'clsx';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  CssBaseline,
  Menu,
  MenuItem,
  Button,
  Hidden,
  Toolbar,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useAuthContext } from '../../../context/authContext';
import Logo from '../../../assets/logo.svg';
import Breadcrumbs from '../../Breadcrumbs';
import ProfileIcon from '../../../assets/ProfileIcon';
import BillingIcon from '../../../assets/BillingIcon';
import LogoutIcon from '../../../assets/LogoutIcon';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  headerIcon: {
    backgroundColor: (props) =>
      !props.paid ? '#fff' : theme.palette.background.headerIcon,
    // flexGrow: 1,
  },
}));

const Header = ({
  open,
  isMobileOpen,
  handleDrawerToggle,
  handleMobileDrawerToggle,
}) => {
  const location = useLocation();
  const paid =
    location.pathname === '/pro-package' ||
    location.pathname.includes('checkout');
  const classes = useStyles({ paid: !paid });
  const { user, setUser } = useAuthContext();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* <CssBaseline /> */}
      {user.isAuthenticated && (
        <AppBar position="fixed" className={classes.appBar}>
          {/* <Toolbar> */}
          <Box display="flex" justifyContent="space-between">
            <Box display="flex">
              <Box
                py={4}
                width="200px"
                display="flex"
                className={classes.headerIcon}
                alignItems="center"
                justifyContent="center"
              >
                <img width="48px" src={Logo} alt="logo" />
                <Typography
                  variant="h6"
                  style={{ color: !paid ? '#fff' : '#002A46' }}
                >
                  SembleScript
                </Typography>
              </Box>
              {!paid && (
                <Box
                  ml={5}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Breadcrumbs />
                </Box>
              )}
            </Box>
            {user.isAuthenticated ? (
              <Box display="flex" alignItems="center">
                <Typography variant="body1" style={{ color: 'black' }}>
                  {user?.data?.name}
                </Typography>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                >
                  <AccountCircle />
                </IconButton>

                <Menu
                  id="menu-appbar"
                  color="disabled"
                  PaperProps={{
                    style: {
                      width: '250px',
                    },
                  }}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={isOpen}
                  onClose={handleClose}
                >
                  <MenuItem>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body1">{user.data?.name}</Typography>
                      <Typography variant="body2">
                        {user.data?.email}
                      </Typography>
                    </Box>
                  </MenuItem>
                  <MenuItem onClick={handleClose} alignItems="center">
                    <Box mr={3}>
                      <ProfileIcon />
                    </Box>
                    Profile
                  </MenuItem>
                  <MenuItem alignItems="center">
                    <Box mr={3}>
                      <BillingIcon fill="#66869B" />
                    </Box>
                    Billing
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setUser({
                        isAuthenticated: false,
                        token: null,
                        data: {},
                      });
                      handleClose();
                    }}
                    style={{ color: '#CF352E' }}
                  >
                    <Box mr={3}>
                      <LogoutIcon />
                    </Box>
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Button
                // color=""
                onClick={() =>
                  setUser({
                    isAuthenticated: true,
                    token: 'randomtoken',
                    data: { role: 'admin' },
                  })
                }
              >
                LOGIN
              </Button>
            )}
            {/* </Toolbar> */}
          </Box>
        </AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  open: propTypes.bool.isRequired,
  isMobileOpen: propTypes.bool.isRequired,
  handleDrawerToggle: propTypes.func.isRequired,
  handleMobileDrawerToggle: propTypes.func.isRequired,
};

export default Header;
