import React from 'react';

const AccountsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 5.83333V2.5H1.66667V17.5H18.3333V5.83333H10ZM5 15.8333H3.33333V14.1667H5V15.8333ZM5 12.5H3.33333V10.8333H5V12.5ZM5 9.16667H3.33333V7.5H5V9.16667ZM5 5.83333H3.33333V4.16667H5V5.83333ZM8.33333 15.8333H6.66667V14.1667H8.33333V15.8333ZM8.33333 12.5H6.66667V10.8333H8.33333V12.5ZM8.33333 9.16667H6.66667V7.5H8.33333V9.16667ZM8.33333 5.83333H6.66667V4.16667H8.33333V5.83333ZM16.6667 15.8333H10V14.1667H11.6667V12.5H10V10.8333H11.6667V9.16667H10V7.5H16.6667V15.8333ZM15 9.16667H13.3333V10.8333H15V9.16667ZM15 12.5H13.3333V14.1667H15V12.5Z"
        fill="white"
      />
    </svg>
  );
};

export default AccountsIcon;
