import React from 'react';

const ScriptsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8333 2.16667V13.8333H2.16667V2.16667H13.8333ZM13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5Z"
        fill="white"
      />
      <path
        d="M9.66667 12.1668H3.83333V10.5002H9.66667V12.1668ZM12.1667 8.8335H3.83333V7.16683H12.1667V8.8335ZM12.1667 5.50016H3.83333V3.8335H12.1667V5.50016Z"
        fill="#66869B"
      />
    </svg>
  );
};

export default ScriptsIcon;
