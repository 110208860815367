import React from 'react';

const SystemStatusIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6666 2.5H3.33329C2.41663 2.5 1.66663 3.25 1.66663 4.16667V13.3333C1.66663 14.25 2.41663 15 3.33329 15H5.83329L4.99996 15.8333V17.5H15V15.8333L14.1666 15H16.6666C17.5833 15 18.3333 14.25 18.3333 13.3333V4.16667C18.3333 3.25 17.5833 2.5 16.6666 2.5ZM16.6666 13.3333H3.33329V4.16667H16.6666V13.3333Z"
        fill="white"
      />
    </svg>
  );
};

export default SystemStatusIcon;
