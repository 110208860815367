import React from 'react';

const ProfileIcon = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66671 9.99996V9.56663C1.66671 9.33996 1.77337 9.12663 1.94004 9.02663C3.06671 8.35329 4.35337 7.99996 5.66671 7.99996C5.68671 7.99996 5.70004 7.99996 5.72004 8.00663C5.78671 7.53996 5.92004 7.09329 6.11337 6.68663C5.96671 6.67329 5.82004 6.66663 5.66671 6.66663C4.05337 6.66663 2.54671 7.11329 1.26004 7.87996C0.673374 8.22663 0.333374 8.87996 0.333374 9.56663V11.3333H6.50671C6.22671 10.9333 6.00671 10.48 5.86004 9.99996H1.66671Z"
        fill="#66869B"
      />
      <path
        d="M5.66667 5.99996C7.14 5.99996 8.33333 4.80663 8.33333 3.33329C8.33333 1.85996 7.14 0.666626 5.66667 0.666626C4.19333 0.666626 3 1.85996 3 3.33329C3 4.80663 4.19333 5.99996 5.66667 5.99996ZM5.66667 1.99996C6.4 1.99996 7 2.59996 7 3.33329C7 4.06663 6.4 4.66663 5.66667 4.66663C4.93333 4.66663 4.33333 4.06663 4.33333 3.33329C4.33333 2.59996 4.93333 1.99996 5.66667 1.99996Z"
        fill="#4D728A"
      />
      <path
        d="M12.8334 8.66658C12.8334 8.51992 12.8134 8.38658 12.7934 8.24659L13.5534 7.57325L12.8867 6.41992L11.9201 6.74659C11.7067 6.56659 11.4667 6.42659 11.2001 6.32659L11.0001 5.33325H9.66674L9.46674 6.32659C9.20007 6.42659 8.96007 6.56659 8.74674 6.74659L7.78007 6.41992L7.1134 7.57325L7.8734 8.24659C7.8534 8.38658 7.8334 8.51992 7.8334 8.66658C7.8334 8.81325 7.8534 8.94658 7.8734 9.08658L7.1134 9.75992L7.78007 10.9133L8.74674 10.5866C8.96007 10.7666 9.20007 10.9066 9.46674 11.0066L9.66674 11.9999H11.0001L11.2001 11.0066C11.4667 10.9066 11.7067 10.7666 11.9201 10.5866L12.8867 10.9133L13.5534 9.75992L12.7934 9.08658C12.8134 8.94658 12.8334 8.81325 12.8334 8.66658ZM10.3334 9.99992C9.60007 9.99992 9.00007 9.39992 9.00007 8.66658C9.00007 7.93325 9.60007 7.33325 10.3334 7.33325C11.0667 7.33325 11.6667 7.93325 11.6667 8.66658C11.6667 9.39992 11.0667 9.99992 10.3334 9.99992Z"
        fill="#4D728A"
      />
    </svg>
  );
};

export default ProfileIcon;
