import React, { useState } from 'react';
import propTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, Box } from '@material-ui/core';
import Header from './header';
import SideBar from './sidebar';
import { useAuthContext } from '../../context/authContext';
import { useLocation } from 'react-router-dom';

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '100%',
    height: (user) => (user.isAuthenticated ? '90%' : '100%'),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: '#E5E5E5',
    height: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: ({ user, paid }) => (!paid ? -drawerWidth : '0px'),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: ({ user, paid }) =>
      user.isAuthenticated && !paid ? drawerWidth : '0px',
    marginTop: ({ user }) => (user.isAuthenticated ? '60px' : '0px'),
  },
}));

const MainLayOut = ({ children }) => {
  const location = useLocation();
  const paid =
    location.pathname === '/pro-package' ||
    location.pathname.includes('checkout');

  const { user } = useAuthContext();
  const [open, setOpen] = useState(true);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const classes = useStyles({ user, paid });

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleMobileDrawerToggle = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  return (
    <div className={classes.root}>
      <Header
        open={open}
        isMobileOpen={isMobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        handleMobileDrawerToggle={handleMobileDrawerToggle}
      />
      {user.isAuthenticated && !paid && (
        <SideBar
          open={open}
          isMobileOpen={isMobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          handleMobileDrawerToggle={handleMobileDrawerToggle}
        />
      )}
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <Box mx="50px" height="100%">
          {children}
        </Box>
      </main>
    </div>
  );
};

MainLayOut.propTypes = {
  children: propTypes.node,
};

MainLayOut.defaultProps = {
  children: {},
};

export default MainLayOut;
