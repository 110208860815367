import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { ROLES } from '../../../utils/constants';
import ProspectsIcon from '../../../assets/prospectsIcon';
import MartketingIcon from '../../../assets/MartketingIcon';
import ScriptsIcon from '../../../assets/ScriptsIcon';
import BillingIcon from '../../../assets/BillingIcon';
import AccountsIcon from '../../../assets/AccountsIcon';
import MessageIcon from '../../../assets/MessageIcon';
import SystemStatusIcon from '../../../assets/SystemStatusIcon';
import SnippetMasterIcon from '../../../assets/SnippetMasterIcon';
import CategoryIcon from '../../../assets/CategoryIcon';
import RankingIcon from '../../../assets/RankingIcon';
import ClientMasterIcon from '../../../assets/ClientMasterIcon';
import TransactionIcon from '../../../assets/TransactionIcon';

const sidebarItems = [
  {
    text: 'Prospects',
    icon: <ProspectsIcon />,
    link: '/prospects',
    role: [ROLES.USER],
  },
  {
    text: 'Accounts',
    icon: <AccountsIcon />,
    link: '/accounts',
    role: [ROLES.USER],
  },

  {
    text: 'Marketing',
    icon: <MartketingIcon />,
    link: '/marketing',
    role: [ROLES.USER],
  },
  {
    text: 'Scripts',
    icon: <ScriptsIcon />,
    link: '/scripts',
    role: [ROLES.USER],
  },
  {
    text: 'Generate Email',
    icon: <MessageIcon />,
    link: '/generate-email',
    role: [ROLES.USER],
  },
  {
    text: 'Billing',
    icon: <BillingIcon />,
    link: '/billing',
    role: [ROLES.USER],
  },
  {
    text: 'System Status',
    icon: <SystemStatusIcon />,
    link: '/system-status',
    role: [ROLES.ADMIN],
  },
  {
    text: 'Email Types',
    icon: <MessageIcon />,
    link: '/email-type',
    role: [ROLES.ADMIN],
  },
  {
    text: 'Snippet Master',
    icon: <SnippetMasterIcon />,
    link: '/snippet-master',
    role: [ROLES.ADMIN],
  },
  {
    text: 'Category',
    icon: <CategoryIcon />,
    link: '/category',
    role: [ROLES.ADMIN],
  },
  {
    text: 'Ranking',
    icon: <RankingIcon />,
    link: '/ranking',
    role: [ROLES.ADMIN],
  },
  {
    text: 'Client Master',
    icon: <ClientMasterIcon />,
    link: '/client-master',
    role: [ROLES.ADMIN],
  },
  {
    text: 'Transactions',
    icon: <TransactionIcon />,
    link: '/transactions',
    role: [ROLES.ADMIN],
  },
];

export default sidebarItems;
