import React from 'react';

const MartketingIcon = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 3.99984V2.33317H7.33333V3.99984H10.6667ZM2.33333 5.6665V14.8332H15.6667V5.6665H2.33333ZM15.6667 3.99984C16.5917 3.99984 17.3333 4.7415 17.3333 5.6665V14.8332C17.3333 15.7582 16.5917 16.4998 15.6667 16.4998H2.33333C1.40833 16.4998 0.666667 15.7582 0.666667 14.8332L0.675 5.6665C0.675 4.7415 1.40833 3.99984 2.33333 3.99984H5.66667V2.33317C5.66667 1.40817 6.40833 0.666504 7.33333 0.666504H10.6667C11.5917 0.666504 12.3333 1.40817 12.3333 2.33317V3.99984H15.6667Z"
        fill="white"
      />
    </svg>
  );
};

export default MartketingIcon;
