export const LOCAL_STORAGE_ENTRIES = { user: 'user' };
export const ROLES = {
  ADMIN: 1,
  USER: 2,
};

export const USER_ROLES = [
  { id: 1, name: 'admin' },
  { id: 2, name: 'client' },
];
