import React from 'react';

const RankingIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.08337 14.1666H3.75004V14.5833H2.91671V15.4166H3.75004V15.8333H2.08337V16.6666H4.58337V13.3333H2.08337V14.1666ZM2.91671 6.66658H3.75004V3.33325H2.08337V4.16659H2.91671V6.66658ZM2.08337 9.16658H3.58337L2.08337 10.9166V11.6666H4.58337V10.8333H3.08337L4.58337 9.08325V8.33325H2.08337V9.16658ZM6.25004 4.16659V5.83325H17.9167V4.16659H6.25004ZM6.25004 15.8333H17.9167V14.1666H6.25004V15.8333ZM6.25004 10.8333H17.9167V9.16658H6.25004V10.8333Z"
        fill="white"
      />
    </svg>
  );
};

export default RankingIcon;
